<template>
  <b-card
    class="dashboard-line-chart"
    no-body
  >
    <b-card-body class="pb-0">
      <b-row>
        <b-col>
          <b-avatar
            class="mb-1"
            :variant="`light-${color}`"
            size="45"
          >
            <feather-icon
              size="21"
              :icon="icon"
            />
          </b-avatar>
        </b-col>
        <b-col
          v-if="loading"
          cols="auto"
        >
          <b-spinner small />
        </b-col>
      </b-row>
      <div class="truncate">
        <b-row
          class="mb-25"
          no-gutters
        >
          <b-col cols="auto">
            <h2 class="mb-0 font-weight-bolder">
              {{ statistic }}
            </h2>
          </b-col>
          <b-col
            v-if="diffPercentage !== null && !isNaN(diffPercentage) && diffPercentage !== Infinity"
            cols="auto"
          >
            <b-badge
              v-if="diffPercentage < 0"
              v-b-tooltip.v-dark.top="`Compare to yesterday`"
              variant="light-primary"
              class="mt-25 ml-50"
            >
              <feather-icon icon="ArrowDownIcon" />
              {{ diffPercentage * -1 | number }}%
            </b-badge>
            <b-badge
              v-else
              v-b-tooltip.v-dark.top="`Compare to yesterday`"
              variant="light-success"
              class="mt-25 ml-50"
            >
              <feather-icon icon="ArrowUpIcon" />
              {{ diffPercentage | number }}%
            </b-badge>
          </b-col>
          <b-col
            v-else-if="diffPercentage !== null"
            cols="auto"
          >
            <b-badge
              v-b-tooltip.v-dark.top="`Compare to yesterday`"
              variant="light-secondary"
              class="mt-25 ml-50"
            ><span class="mx-25">=</span></b-badge>
          </b-col>
          <b-col
            v-if="updatedAt"
          >
            <feather-icon
              v-b-tooltip.v-dark.top="`Last update: ${lastUpdate}`"
              size="20"
              icon="InfoIcon"
              class="text-muted mt-25 ml-1"
            />
          </b-col>
        </b-row>
        <span>{{ statisticTitle }}</span>
      </div>
    </b-card-body>

    <vue-apex-charts
      type="area"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BAvatar, BRow, BCol, BBadge, BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import moment from 'moment'
import { areaChartOptions } from './chartOptions'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BSpinner,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    diffRatio: {
      type: Number,
      default: null,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    updatedAt: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(areaChartOptions))
      options.theme.monochrome.color = $themeColors[this.color]

      if (this.chartOptions !== null) {
        return this._.merge(options, this.chartOptions)
      }

      return options
    },
    lastUpdate() {
      return moment(this.updatedAt).fromNow()
    },
    diffPercentage() {
      if (this.diffRatio === null) {
        return null
      }

      return Math.round(this.diffRatio * 100)
    },
  },
}
</script>

<style>
/* Force hide y axis labels */
/* Due to strange bug from the package, */
/* Happened when the visible chart is re-render */
.dashboard-line-chart .apexcharts-yaxis {
  display: none;
}
</style>
