<template>
  <b-card no-body>
    <b-card-header class="pb-0">
      <b-row
        align-v="center"
        class="w-100"
        no-gutters
      >
        <b-col>
          <b-card-title>
            User Interest
          </b-card-title>
        </b-col>
        <b-col
          cols="auto"
          class="text-muted py-50"
        >
          All time
        </b-col>
      </b-row>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="532"
        :options="apexChatData.barChart.chartOptions"
        :series="apexChatData.barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
  },
  data() {
    return {
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
}
</script>
