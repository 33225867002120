<template>
  <div>
    <!-- Today's Statistics -->
    <div>
      <b-row>
        <!-- Transaction -->
        <b-col
          v-if="transactionCountData.length"
          cols="6"
          md="3"
        >
          <LineChart
            icon="ListIcon"
            color="primary"
            :statistic="$options.filters.number(transactionCountData[transactionCountData.length - 1])"
            :diff-ratio="transactionCountData[transactionCountData.length - 1] / transactionCountData[transactionCountData.length - 2] - 1"
            :updated-at="countUpdatedAt"
            statistic-title="Today's transaction"
            :chart-data="transactionData"
            :chart-options="transactionOptions"
            :loading="loadingStatisticCount"
          />
        </b-col>
        <b-col
          v-else-if="loadingStatisticCount"
          cols="6"
          md="3"
        >
          <b-skeleton
            height="233px"
          />
        </b-col>

        <!-- Registration -->
        <b-col
          v-if="registrationCountData.length"
          cols="6"
          md="3"
        >
          <LineChart
            icon="UserIcon"
            color="warning"
            :statistic="$options.filters.number(registrationCountData[registrationCountData.length - 1])"
            :diff-ratio="registrationCountData[registrationCountData.length - 1] / registrationCountData[registrationCountData.length - 2] - 1"
            :updated-at="countUpdatedAt"
            statistic-title="Today's registration"
            :chart-data="registrationData"
            :chart-options="registrationOptions"
            :loading="loadingStatisticCount"
          />
        </b-col>
        <b-col
          v-else-if="loadingStatisticCount"
          cols="6"
          md="3"
        >
          <b-skeleton
            height="233px"
          />
        </b-col>

        <!-- Donation -->
        <b-col
          v-if="donationAmountData.length"
          cols="6"
          md="3"
        >
          <LineChart
            icon="DollarSignIcon"
            color="success"
            :statistic="`$${kFormatter(donationAmountData[donationAmountData.length - 1])} USD`"
            :diff-ratio="donationAmountData[donationAmountData.length - 1] / donationAmountData[donationAmountData.length - 2] - 1"
            :updated-at="amountUpdatedAt"
            statistic-title="Today's donation"
            :chart-data="donationData"
            :chart-options="donationOptions"
            :loading="loadingStatisticAmount"
          />
        </b-col>
        <!-- Donation -->
        <b-col
          v-else-if="loadingStatisticAmount"
          cols="6"
          md="3"
        >
          <b-skeleton
            height="233px"
          />
        </b-col>

        <!-- Subscription -->
        <b-col
          v-if="subscriptionAmountData.length"
          cols="6"
          md="3"
        >
          <LineChart
            icon="BellIcon"
            color="info"
            :statistic="`$${kFormatter(subscriptionAmountData[subscriptionAmountData.length - 1])} USD`"
            :diff-ratio="subscriptionAmountData[subscriptionAmountData.length - 1] / subscriptionAmountData[subscriptionAmountData.length - 2] - 1"
            :updated-at="amountUpdatedAt"
            statistic-title="Today's subscription"
            :chart-data="subscriptionData"
            :chart-options="subscriptionOptions"
            :loading="loadingStatisticAmount"
          />
        </b-col>
        <b-col
          v-else-if="loadingStatisticAmount"
          cols="6"
          md="3"
        >
          <b-skeleton
            height="233px"
          />
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col
        v-if="revenueServicesData.length"
        xl="6"
      >
        <b-card no-body>
          <!-- title and dropdown -->
          <b-card-header class="pb-0">
            <b-card-title class="d-flex align-items-center">
              Revenue by Services (USD) <b-spinner
                v-if="loadingRevenueServices"
                class="ml-50 s-14"
                small
              />
            </b-card-title>
            <b-dropdown
              no-caret
              right
              :text="daysLimitRevenueByServices.text"
              variant="transparent"
              class="chart-dropdown"
              toggle-class="p-50"
              size="sm"
            >
              <b-dropdown-item
                v-for="(day, index) in lastDays"
                :key="`revenue-services-days-limit-${index}`"
                @click="selectDaysLimitRevenueByServices(day)"
              >
                {{ day.text }}
              </b-dropdown-item>
              <b-dropdown-divider v-if="false" />
              <b-dropdown-item v-if="false">
                Custom
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <!--/ title and dropdown -->

          <b-card-body>
            <!-- -->
            <vue-apex-charts
              type="line"
              height="400"
              :options="chartOptionsRevenueByServices"
              :series="revenueServicesData"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-else-if="loadingRevenueServices"
        xl="6"
      >
        <b-skeleton
          height="487px"
        />
      </b-col>

      <b-col
        v-if="revenueRegionsData.length"
        xl="6"
      >
        <b-card no-body>
          <!-- title and dropdown -->
          <b-card-header class="pb-0">
            <b-card-title class="d-flex align-items-center">
              Revenue by Regions (USD) <b-spinner
                v-if="loadingRevenueRegions"
                class="ml-50 s-14"
                small
              />
            </b-card-title>
            <b-dropdown
              no-caret
              right
              :text="daysLimitRevenueByRegions.text"
              variant="transparent"
              class="chart-dropdown"
              toggle-class="p-50"
              size="sm"
            >
              <b-dropdown-item
                v-for="(day, index) in lastDays"
                :key="`revenue-regions-days-limit-${index}`"
                @click="selectDaysLimitRevenueByRegions(day)"
              >
                {{ day.text }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item>
                Custom
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <!--/ title and dropdown -->

          <b-card-body>
            <!-- -->
            <vue-apex-charts
              type="line"
              height="400"
              :options="chartOptionsRevenueByRegion"
              :series="revenueRegionsData"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-else-if="loadingRevenueRegions"
        xl="6"
      >
        <b-skeleton
          height="487px"
        />
      </b-col>
    </b-row>

    <hr class="mt-n50 mb-2">

    <!-- Creator Leaderboard -->
    <b-row>
      <b-col
        v-if="creatorLeaderboardData.length"
        xl="4"
      >
        <b-card no-body>
          <!-- title and dropdown -->
          <b-card-header class="pb-0">
            <b-card-title class="d-flex align-items-center">
              Creator Leaderboard <b-spinner
                v-if="loadingCreatorLeaderboard"
                class="ml-50 s-14"
                small
              />
            </b-card-title>
            <b-dropdown
              no-caret
              right
              :text="daysLimitCreatorLeaderBoard.text"
              variant="transparent"
              class="chart-dropdown"
              toggle-class="p-50"
              size="sm"
            >
              <b-dropdown-item
                v-for="(day, index) in lastDays"
                :key="`revenue-services-days-limit-${index}`"
                @click="selectDaysLimitCreatorLeaderBoard(day)"
              >
                {{ day.text }}
              </b-dropdown-item>
              <b-dropdown-divider v-if="false" />
              <b-dropdown-item v-if="false">
                Custom
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <!--/ title and dropdown -->

          <b-list-group
            class="my-1"
            flush
          >
            <b-list-group-item
              v-for="(item, index) in creatorLeaderboardData"
              :key="`leaderboard-${index}`"
            >
              <b-row align-v="center">
                <b-col
                  cols="2"
                  class="text-center"
                >
                  <div
                    v-if="index === 0"
                    class="ml-50"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 8.20349C10.9688 8.20349 10.125 7.35974 10.125 6.32849C10.125 5.27399 10.9688 4.45349 12 4.45349C13.0312 4.45349 13.875 5.27399 13.875 6.32849C13.875 7.35974 13.0312 8.20349 12 8.20349ZM4.52325 7.42949C5.34375 7.42949 6 8.10899 6 8.92949C6 9.77324 5.34375 10.4295 4.52325 10.4295C3.6795 10.4295 3 9.77324 3 8.92949C3 8.10899 3.6795 7.42949 4.52325 7.42949ZM19.5233 7.42949C20.3438 7.42949 21 8.10899 21 8.92949C21 9.77324 20.3438 10.4295 19.5233 10.4295C18.6795 10.4295 18 9.77324 18 8.92949C18 8.10899 18.6795 7.42949 19.5233 7.42949ZM6.16425 17.7892L4.92225 10.992C5.29725 10.9215 5.649 10.7107 5.93025 10.476C6.7035 11.1795 7.68825 11.859 8.62575 11.859C9.75075 11.859 10.665 10.1242 11.298 8.67149C11.5088 8.74199 11.7667 8.81249 12.0015 8.81249C12.2362 8.81249 12.4935 8.74199 12.705 8.67149C13.338 10.125 14.2523 11.859 15.3773 11.859C16.3148 11.859 17.2995 11.1795 18.0728 10.476C18.354 10.7107 18.7057 10.9215 19.0807 10.992L17.8387 17.7892H6.1665H6.16425ZM5.90625 18.375H18.0938V19.5232H5.90625V18.375Z"
                        fill="#F4CD00"
                      />
                    </svg>
                  </div>
                  <div
                    v-else-if="index === 1"
                    class="ml-50"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 8.20349C10.9688 8.20349 10.125 7.35974 10.125 6.32849C10.125 5.27399 10.9688 4.45349 12 4.45349C13.0312 4.45349 13.875 5.27399 13.875 6.32849C13.875 7.35974 13.0312 8.20349 12 8.20349ZM4.52325 7.42949C5.34375 7.42949 6 8.10899 6 8.92949C6 9.77324 5.34375 10.4295 4.52325 10.4295C3.6795 10.4295 3 9.77324 3 8.92949C3 8.10899 3.6795 7.42949 4.52325 7.42949ZM19.5233 7.42949C20.3438 7.42949 21 8.10899 21 8.92949C21 9.77324 20.3438 10.4295 19.5233 10.4295C18.6795 10.4295 18 9.77324 18 8.92949C18 8.10899 18.6795 7.42949 19.5233 7.42949ZM6.16425 17.7892L4.92225 10.992C5.29725 10.9215 5.649 10.7107 5.93025 10.476C6.7035 11.1795 7.68825 11.859 8.62575 11.859C9.75075 11.859 10.665 10.1242 11.298 8.67149C11.5088 8.74199 11.7667 8.81249 12.0015 8.81249C12.2362 8.81249 12.4935 8.74199 12.705 8.67149C13.338 10.125 14.2523 11.859 15.3773 11.859C16.3148 11.859 17.2995 11.1795 18.0728 10.476C18.354 10.7107 18.7057 10.9215 19.0807 10.992L17.8387 17.7892H6.1665H6.16425ZM5.90625 18.375H18.0938V19.5232H5.90625V18.375Z"
                        fill="#B8B8B8"
                      />
                    </svg>
                  </div>
                  <div
                    v-else-if="index === 2"
                    class="ml-50"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 8.20349C10.9688 8.20349 10.125 7.35974 10.125 6.32849C10.125 5.27399 10.9688 4.45349 12 4.45349C13.0312 4.45349 13.875 5.27399 13.875 6.32849C13.875 7.35974 13.0312 8.20349 12 8.20349ZM4.52325 7.42949C5.34375 7.42949 6 8.10899 6 8.92949C6 9.77324 5.34375 10.4295 4.52325 10.4295C3.6795 10.4295 3 9.77324 3 8.92949C3 8.10899 3.6795 7.42949 4.52325 7.42949ZM19.5233 7.42949C20.3438 7.42949 21 8.10899 21 8.92949C21 9.77324 20.3438 10.4295 19.5233 10.4295C18.6795 10.4295 18 9.77324 18 8.92949C18 8.10899 18.6795 7.42949 19.5233 7.42949ZM6.16425 17.7892L4.92225 10.992C5.29725 10.9215 5.649 10.7107 5.93025 10.476C6.7035 11.1795 7.68825 11.859 8.62575 11.859C9.75075 11.859 10.665 10.1242 11.298 8.67149C11.5088 8.74199 11.7667 8.81249 12.0015 8.81249C12.2362 8.81249 12.4935 8.74199 12.705 8.67149C13.338 10.125 14.2523 11.859 15.3773 11.859C16.3148 11.859 17.2995 11.1795 18.0728 10.476C18.354 10.7107 18.7057 10.9215 19.0807 10.992L17.8387 17.7892H6.1665H6.16425ZM5.90625 18.375H18.0938V19.5232H5.90625V18.375Z"
                        fill="#C47930"
                      />
                    </svg>
                  </div>
                  <span
                    v-else
                    class="ml-50"
                  >{{ index + 1 }}</span>
                </b-col>
                <b-col>
                  <TableField
                    type="profile"
                    :image="item.user.avatar"
                    :value="item.user.nickname"
                    :url="`/user/${item.user_id}`"
                  />
                </b-col>
                <b-col
                  cols="auto"
                  class="mr-75"
                >
                  ${{ kFormatter(Math.round(item.amount)) }} USD
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col
        v-else-if="loadingCreatorLeaderboard"
        xl="4"
      >
        <b-skeleton
          height="487px"
        />
      </b-col>

      <b-col
        v-if="false"
        xl="4"
      >
        <UserInterest />
      </b-col>

      <b-col
        v-if="false"
        xl="4"
      >
        <StreamReport />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LineChart from '@/layouts/components/Chart/LineChart.vue'
import StreamReport from '@/layouts/components/Chart/StreamReport.vue'
import UserInterest from '@/layouts/components/Chart/UserInterest.vue'
// eslint-disable-next-line import/no-cycle
import { kFormatter } from '@core/utils/filter'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import {
  BRow,
  BCol,
  BSkeleton,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue'
import TableField from '@/layouts/components/TableField.vue'

export default {
  components: {
    BRow,
    BCol,
    BSkeleton,
    LineChart,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BListGroup,
    BListGroupItem,
    VueApexCharts,
    TableField,
    StreamReport,
    UserInterest,
    BSpinner,
  },
  data() {
    return {
      abortController: null,

      daysLimit: 7,
      daysLimitRevenueByServices: {
        value: 7,
        text: 'Last 7 Days',
      },
      daysLimitRevenueByRegions: {
        value: 7,
        text: 'Last 7 Days',
      },
      daysLimitCreatorLeaderBoard: {
        value: 7,
        text: 'Last 7 Days',
      },
      lastDays: [
        {
          value: 7,
          text: 'Last 7 Days',
        },
        {
          value: 14,
          text: 'Last 14 Days',
        },
        {
          value: 30,
          text: 'Last 30 Days',
        },
      ],
      seriesRegion: [
        {
          name: 'Philippines',
          data: [50, 40, 30, 70, 40, 60, 50],
        },
        {
          name: 'Thailand',
          data: [100, 120, 90, 170, 130, 160, 130],
        },
        {
          name: 'Malaysia',
          data: [200, 280, 270, 210, 280, 220, 290],
        },
        {
          name: 'Singapore',
          data: [140, 160, 80, 190, 180, 210, 170],
        },
        {
          name: 'Indonesia',
          data: [20, 80, 70, 140, 80, 100, 90],
        },
      ],
    }
  },
  computed: {
    ...mapState('dashboard', [
      'creatorLeaderboardData',
      'loadingStatisticCount',
      'loadingStatisticAmount',
      'loadingRevenueServices',
      'loadingRevenueRegions',
      'loadingCreatorLeaderboard',
    ]),
    ...mapGetters({
      countUpdatedAt: 'dashboard/countUpdatedAt',
      amountUpdatedAt: 'dashboard/amountUpdatedAt',
      revenueServicesUpdatedAt: 'dashboard/revenueServicesUpdatedAt',
      revenueRegionsUpdatedAt: 'dashboard/revenueRegionsUpdatedAt',

      xAxisCountLabels: 'dashboard/xAxisCountLabels',
      xAxisAmountLabels: 'dashboard/xAxisAmountLabels',
      xAxisRevenueServicesLabels: 'dashboard/xAxisRevenueServicesLabels',
      xAxisRevenueRegionsLabels: 'dashboard/xAxisRevenueRegionsLabels',

      transactionCountData: 'dashboard/transactionCountData',
      registrationCountData: 'dashboard/registrationCountData',
      donationAmountData: 'dashboard/donationAmountData',
      subscriptionAmountData: 'dashboard/subscriptionAmountData',
      revenueServicesData: 'dashboard/revenueServicesData',
      revenueRegionsData: 'dashboard/revenueRegionsData',
    }),
    transactionOptions() {
      return {
        xaxis: {
          type: 'datetime',
          categories: this.xAxisCountLabels.slice(0, this.daysLimit),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: { show: true, format: 'MMM d' },
          y: {
            formatter: value => this.$options.filters.number(value),
          },
        },
      }
    },
    transactionData() {
      return [
        {
          name: 'Transaction',
          data: this.transactionCountData.slice(0, this.daysLimit),
        },
      ]
    },
    registrationOptions() {
      return {
        xaxis: {
          type: 'datetime',
          categories: this.xAxisCountLabels.slice(0, this.daysLimit),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: { show: true, format: 'MMM d' },
          y: {
            formatter: value => this.$options.filters.number(value),
          },
        },
      }
    },
    registrationData() {
      return [
        {
          name: 'Registration',
          data: this.registrationCountData.slice(0, this.daysLimit),
        },
      ]
    },
    donationOptions() {
      return {
        xaxis: {
          type: 'datetime',
          categories: this.xAxisAmountLabels.slice(0, this.daysLimit),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: { show: true, format: 'MMM d' },
          y: {
            formatter: value => `$${this.kFormatter(value)} USD`,
          },
        },
      }
    },
    donationData() {
      return [
        {
          name: 'Donation',
          data: this.donationAmountData.slice(0, this.daysLimit),
        },
      ]
    },
    subscriptionOptions() {
      return {
        xaxis: {
          type: 'datetime',
          categories: this.xAxisAmountLabels.slice(0, this.daysLimit),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          x: { show: true, format: 'MMM d' },
          y: {
            formatter: value => `$${this.kFormatter(value)} USD`,
          },
        },
      }
    },
    subscriptionData() {
      return [
        {
          name: 'Subscription',
          data: this.subscriptionAmountData.slice(0, this.daysLimit),
        },
      ]
    },
    chartOptionsRevenueByServices() {
      return {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [
            $themeColors.success,
            $themeColors.info,
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.secondary,
            $themeColors.dark,
          ],
          colors: [
            $themeColors.success,
            $themeColors.info,
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.secondary,
            $themeColors.dark,
          ],
        },
        colors: [
          $themeColors.success,
          $themeColors.info,
          $themeColors.primary,
          $themeColors.warning,
          $themeColors.secondary,
          $themeColors.dark,
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          categories: this.xAxisRevenueServicesLabels.slice(0, this.daysLimitRevenueByServices.value),
          labels: {
            formatter: value => moment(value).format('MMM D'),
          },
        },
        yaxis: {
          labels: {
            formatter: value => `$${this.kFormatter(value)}`,
          },
        },
        tooltip: {
          x: { show: true },
          y: {
            formatter: value => `$${this.kFormatter(value)}`,
          },
        },
      }
    },
    chartOptionsRevenueByRegion() {
      return {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [
            $themeColors.success,
            $themeColors.secondary,
            $themeColors.warning,
            $themeColors.info,
            $themeColors.primary,
          ],
          colors: [
            $themeColors.success,
            $themeColors.secondary,
            $themeColors.warning,
            $themeColors.info,
            $themeColors.primary,
          ],
        },
        colors: [
          $themeColors.success,
          $themeColors.secondary,
          $themeColors.warning,
          $themeColors.info,
          $themeColors.primary,
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          categories: this.xAxisRevenueRegionsLabels.slice(0, this.daysLimitRevenueByRegions.value),
          labels: {
            formatter: value => moment(value).format('MMM D'),
          },
        },
        yaxis: {
          labels: {
            formatter: value => `$${this.kFormatter(value)}`,
          },
        },
        tooltip: {
          x: { show: true },
          y: {
            formatter: value => `$${this.kFormatter(value)}`,
          },
        },
      }
    },
  },
  beforeRouteLeave(_, __, next) {
    this.abortController.abort()
    next()
  },
  mounted() {
    this.abortController = new AbortController()

    // delay load data
    setTimeout(this.loadData, 2000)
  },
  methods: {
    kFormatter,
    loadData() {
      // check is still on the dashboard page
      if (this.$route.name !== 'dashboard') {
        return
      }

      const { signal } = this.abortController

      // get statistic data
      this.$store.dispatch('dashboard/getStatisticCount', {
        signal,
        startedAt: moment().subtract(this.daysLimit, 'days').utc().toISOString(),
        endedAt: moment().utc().toISOString(),
      })

      this.$store.dispatch('dashboard/getStatisticAmount', {
        signal,
        startedAt: moment().subtract(this.daysLimit, 'days').utc().toISOString(),
        endedAt: moment().utc().toISOString(),
      })

      this.loadRevenueByServices()
      this.loadRevenueByRegions()
      this.loadCreatorLeaderboard()
    },
    loadRevenueByServices() {
      const { signal } = this.abortController
      this.$store.dispatch('dashboard/getRevenueByServices', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitRevenueByServices.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString(),
        endedAt: moment().startOf('day').utc()
          .toISOString(),
      })
    },
    loadRevenueByRegions() {
      const { signal } = this.abortController
      this.$store.dispatch('dashboard/getRevenueByRegions', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitRevenueByRegions.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString(),
        endedAt: moment().startOf('day').utc()
          .toISOString(),
      })
    },
    loadCreatorLeaderboard() {
      const { signal } = this.abortController
      this.$store.dispatch('dashboard/getCreatorLeaderboard', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitCreatorLeaderBoard.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString(),
        endedAt: moment().startOf('day').utc()
          .toISOString(),
      })
    },
    selectDaysLimitRevenueByServices(day) {
      this.daysLimitRevenueByServices = day
      this.loadRevenueByServices()
    },
    selectDaysLimitRevenueByRegions(day) {
      this.daysLimitRevenueByRegions = day
      this.loadRevenueByRegions()
    },
    selectDaysLimitCreatorLeaderBoard(day) {
      this.daysLimitCreatorLeaderBoard = day
      this.loadCreatorLeaderboard()
    },
  },
}
</script>

<style>

</style>
